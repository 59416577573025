import React from "react";
import logo from "../../assets/main_logo.png";
import backgroundVideo from "../../assets/videos/background.mp4"; // Import the video
import "./hero.css";

const Hero = () => {
  return (
    <section className="hero">
      {/* Background video */}
      <video className="background-video" autoPlay loop muted>
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="hero-content">
        <img
          src={logo}
          alt="Manchester City Football School"
          className="hero-logo"
        />
        <p className="hero-text">WHO COULD YOU BECOME?</p>
      </div>
    </section>
  );
};

export default Hero;
