import "./ComingSoonComp.css"; // Import the CSS file

const ComingSoonComp = () => {
  return (
    <div className="registration-container">
      <h1>Pre-Registrations will be starting soon!</h1>
      <h3>Keep an eye on this page!</h3>
    </div>
  );
};

export default ComingSoonComp;
