import "./CFGComp.css"; // Import the CSS file
const CFGComp = () => {
  return (
    <div className="CFG-container">
      <h1>City Football Group (CFG)</h1>
      <p>
        City Football Group (CFG) is a global organization that operates a
        network of top-tier football clubs and businesses around the world.
        Founded in 2013, CFG’s vision is to combine football excellence with
        commercial growth, with its flagship club, Manchester City FC, leading
        the way. CFG also owns or has stakes in clubs such as New York City FC
        (USA), Melbourne City FC (Australia), Girona FC (Spain), Yokohama F.
        Marinos (Japan), and Mumbai City FC (India), among others. This unique
        network enables clubs to share expertise in sports science, coaching,
        and talent development, while also leveraging innovative commercial
        strategies to drive growth and success across the globe.
      </p>
    </div>
  );
};

export default CFGComp;
