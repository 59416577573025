import Navbar from "../../components/Navbar/Navbar";
import "./YBDPDetails.css"; // Import the CSS file for styling
import YBDPComp from "../../components/YBDPComp/YBDPComp";

export default function YBDP() {
  return (
    <>
      <Navbar />
      <div className="YBDP-page">
        <YBDPComp />
      </div>
    </>
  );
}
