import Navbar from "../../components/Navbar/Navbar";
import ComingSoonComp from "../../components/ComingSoonComp/ComingSoonComp";
import "./ComingSoon.css"; // Import the CSS file for styling

export default function ComingSoon() {
  return (
    <>
      <Navbar />
      <div className="coming-soon">
        <ComingSoonComp />
      </div>
    </>
  );
}
