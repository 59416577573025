import Banner from "../../components/Banner/Banner";
import Gallery from "../../components/Gallery/Gallery";
import Hero from "../../components/Hero/Hero";
import Navbar from "../../components/Navbar/Navbar";
import OverViewCFG from "../../components/OverViewCFG/OverViewCFG";
import RegisterSection from "../../components/RegisterSection/RegisterSection";
import TIG from "../../components/TIGBanner/TIGBanner";

export default function Home() {
  return (
    <>
      <Navbar />
      <Hero />
      <Banner />
      <TIG />
      <OverViewCFG />
      <RegisterSection />
      <Gallery />
    </>
  );
}
