import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./RegisterSection.css";
import TIGLogo from "../../assets/images/kolkata/P5.jpeg";

const RegisterSection = () => {
  return (
    <section className="enrol-banner">
      <div className="enrol-banner-left">
        <img src={TIGLogo} alt="Techno India Group Logo" />
      </div>
      <div className="enrol-banner-right">
        <h1>Upcoming Events</h1>
        <p>Head Over to our Registrations Page!</p>
        <Link to="/registration">
          <button className="regneo-btn">Register Now</button>
        </Link>
      </div>
    </section>
  );
};

export default RegisterSection;
