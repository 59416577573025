import { Link } from "react-router-dom"; // Import Link from react-router-dom
import React, { useRef, useEffect } from "react";
import "./banner.css";
import coverImage from "../../assets/Cover_1.png";

const Banner = () => {
  // Create a reference to the image element
  const imageRef = useRef(null);

  useEffect(() => {
    const bannerImage = imageRef.current;

    // Function to handle mouse movement over the image
    const handleMouseMove = (event) => {
      const { offsetX, offsetY, target } = event;
      const { clientWidth, clientHeight } = target;

      // Calculate rotation values based on cursor position
      const rotateX = (offsetY / clientHeight - 0.5) * 20; // Max 20 degrees rotation on X-axis
      const rotateY = (offsetX / clientWidth - 0.5) * 20; // Max 20 degrees rotation on Y-axis

      // Apply the rotation to the image
      bannerImage.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
    };

    // Function to reset the image when the cursor leaves
    const handleMouseLeave = () => {
      bannerImage.style.transform = `rotateX(0deg) rotateY(0deg)`; // Reset to default position
    };

    // Attach event listeners to the image
    bannerImage.addEventListener("mousemove", handleMouseMove);
    bannerImage.addEventListener("mouseleave", handleMouseLeave);

    // Clean up event listeners on component unmount
    return () => {
      bannerImage.removeEventListener("mousemove", handleMouseMove);
      bannerImage.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return (
    <section className="banner">
      <div className="banner-left">
        <h1>YOUNG PLAYER DEVELOPMENT PROGRAMME :</h1>
        <p>
          Our Young Player Development Programme is designed to nurture the next
          generation of football stars. By combining top-tier coaching,
          personalized training, and holistic development, we aim to cultivate
          young talent from the grassroots level. Participants receive
          world-class instruction, access to cutting-edge facilities, and
          opportunities to showcase their skills in competitive environments.
          With a focus on skill development, sportsmanship, and academic growth,
          our programme helps young athletes reach their full potential both on
          and off the field.
        </p>
        <Link to="/YBDP">
          <button className="neomorphic-btn">FIND OUT MORE</button>
        </Link>
      </div>
      <div className="banner-right">
        <img
          ref={imageRef} // Attach the ref to the image
          src={coverImage}
          alt="Young Player Development"
        />
      </div>
    </section>
  );
};

export default Banner;
