import { Component } from "react";
import { Link as RouterLink } from "react-router-dom"; // For navigation to other pages
import logo from "../../assets/main_logo.png";
import "./navbar.css";

class Navbar extends Component {
  state = { clicked: false };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  render() {
    return (
      <>
        <nav>
          <a href="/">
            <img src={logo} alt="abcd" width={225} height={45} />
          </a>
          <div>
            <ul id="Navbar" className={this.state.clicked ? "active" : ""}>
              <li>
                <RouterLink to="/home">Home</RouterLink>
              </li>
              <li>
                <RouterLink to="/tig">About Us</RouterLink>
              </li>
              <li>
                <RouterLink to="/comingsoon">Registration</RouterLink>
              </li>
              {/* <li>
                <RouterLink to="/contact">Contact</RouterLink>
              </li> */}
              {/* Use RouterLink or regular <a> for separate pages */}
            </ul>
          </div>
          <div id="mobile" onClick={this.handleClick}>
            <i
              id="bar"
              className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
            ></i>
          </div>
        </nav>
      </>
    );
  }
}

export default Navbar;
