import React from "react";
import CountdownComp from "./pages/countdown/countdown";
import Home from "./pages/homepage/homepage";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Registration from "./pages/registration/registration";
import ComingSoon from "./pages/ComingSoon/ComingSoon.js";
import About from "./pages/About/About.js";
import Contact from "./pages/Contact/Contact.js";
import YBDP from "./pages/YBDPDetails/YBDPDetails.js";
import TIGDetails from "./pages/TIGDetails/TIGDetails.js";
import CFGDetails from "./pages/CFGDetails/CFGDetails.js";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<CountdownComp />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/ybdp" element={<YBDP />} />
          <Route path="/tig" element={<TIGDetails />} />
          <Route path="/cfg" element={<CFGDetails />} />
          <Route path="/comingsoon" element={<ComingSoon />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
