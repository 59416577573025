import React from "react";
import "./TIGBanner.css";
import TIGLogo from "../../assets/TIG.png";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const TIGBanner = () => {
  return (
    <section className="tig-banner">
      <div className="tig-banner-left">
        <img src={TIGLogo} alt="Techno India Group Logo" />
      </div>
      <div className="tig-banner-right">
        <h1>TECHNO INDIA GROUP</h1>
        <p>
          Techno India Group (TIG) , since 1985, has been reshaping the
          educational landscape with a spark of innovation and a dash of
          boldness. What started as a vision to pioneer computer education has
          grown into a vibrant conglomerate driving progress in technology,
          healthcare, and more. From turning ideas into reality to crafting
          futures with flair, TIG is all about making learning an adventure.
          Join us in rewriting the rules and discovering where your ambitions
          can take you!
        </p>
        <Link to="/tig">
          <button className="neo-btn">Find out more</button>
        </Link>
      </div>
    </section>
  );
};

export default TIGBanner;
