import React, { useState, useEffect } from "react";
import "./gallery.css";
import image1 from "../../assets/images/P1.png";
import image2 from "../../assets/images/P2.png";
import image3 from "../../assets/images/P3.png";
import image4 from "../../assets/images/P4.png";
import image5 from "../../assets/images/P5.png";
import image6 from "../../assets/images/P6.png";
import image7 from "../../assets/images/P7.png";
import image8 from "../../assets/images/P8.png";
import image9 from "../../assets/images/P9.png";
import image10 from "../../assets/images/P10.png";
import image11 from "../../assets/images/P11.png";
import image12 from "../../assets/images/P12.png";
import image13 from "../../assets/images/P13.png";

// Array of all 13 images
const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
];

const Gallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Handle automatic sliding
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Slide every 3 seconds

    // Listener to update isMobile state
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener("resize", handleResize);
    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Open full-screen mode
  const [fullScreenImage, setFullScreenImage] = useState(null);

  const openFullScreen = (image) => {
    setFullScreenImage(image);
  };

  const closeFullScreen = () => {
    setFullScreenImage(null);
  };

  return (
    <div className="gallery-section">
      <h2 className="gallery-heading">Gallery Section</h2>
      <div className="gallery-container">
        <div
          className="gallery"
          style={{
            transform: `translateX(-${currentIndex * (isMobile ? 100 : 33.33)}%)`,
            width: `${images.length * 100}%`,
          }}
        >
          {images.map((image, index) => (
            <div
              key={index}
              className={`gallery-image`}
              onClick={() => openFullScreen(image)}
            >
              <img src={image} alt={`Gallery Item ${index + 1}`} />
            </div>
          ))}
        </div>

        {/* Full-screen overlay */}
        {fullScreenImage && (
          <div className="full-screen-overlay" onClick={closeFullScreen}>
            <img src={fullScreenImage} alt="Full Screen" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Gallery;
