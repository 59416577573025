import Navbar from "../../components/Navbar/Navbar";
import CFGComp from "../../components/CFGComp/CFGComp";
import "./CFGDetails.css"; // Import the CSS file for styling
import Gallery from "../../components/Gallery/Gallery.js";

export default function CFGDetails() {
  return (
    <>
      <Navbar />
      <div className="CFG-page">
        <CFGComp />
        <Gallery />
      </div>
    </>
  );
}
