import Spline from "@splinetool/react-spline";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import { useEffect } from "react"; // Import hooks
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import "./countdown.css"; // Assuming you have a separate CSS file for this component

function CountdownComp() {
  const navigate = useNavigate();

  useEffect(() => {
    const targetTime = new Date("2024-09-12T13:30:00+05:30").getTime();
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const timeDifference = targetTime - currentTime;

      if (timeDifference <= 0) {
        clearInterval(interval);
        navigate("/home"); // Redirect to /home when timer ends
      }
    }, 1000);

    return () => clearInterval(interval); // Clean up the interval
  }, [navigate]);

  return (
    <>
      <div className="text-container">
        <Spline scene="https://prod.spline.design/0o2ACflOMVnAZ-Nw/scene.splinecode" />
        <div className="clock_content">
          <h1>We are Coming Soon!</h1>
          <FlipClockCountdown
            className="flip-clock"
            to={new Date("2024-09-12T13:30:00+05:30")}
            labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
            duration={0.5}
            labelStyle={{
              fontSize: 23,
              fontWeight: 50,
              textTransform: "uppercase",
              fontFamily: "Kippax",
              color: "#1c2c5b",
            }}
          />
        </div>
      </div>
      <div className="gradient-bg"></div>
    </>
  );
}

export default CountdownComp;
