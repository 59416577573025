import { useState } from "react";
import "./RegistrationForm.css"; // Import the CSS file
import axios from "axios";
const RegistrationForm = () => {
  const [, setFormData] = useState({
    name: "",
    age: "",
    gender: "",
    school: "",
    footballExperience: "",
    otherSport: "",
    trophyTour: "",
    phone: "",
    email: "",
  });
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [school, setSchool] = useState("");
  const [footballExperience, setFootballExperience] = useState("");
  const [otherSport, setOtherSport] = useState("");
  const [trophyTour, setTrophyTour] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: name,
      age: age,
      gender: gender,
      school: school,
      footballExperience: footballExperience,
      otherSport: otherSport,
      trophyTour: trophyTour,
      phone: phone,
      email: email,
    };
    axios
      .post(
        "https://sheet.best/api/sheets/65935521-bbb1-4da0-a8e0-4ade014eaa17",
        data
      )
      .then((response) => {
        console.log(response);
        setName("");
        setAge("");
        setGender("");
        setSchool("");
        setFootballExperience("");
        setOtherSport("");
        setTrophyTour("");
        setPhone("");
        setEmail("");
      });
  };

  const handleReset = () => {
    setFormData({
      name: "",
      age: "",
      gender: "",
      school: "",
      footballExperience: "",
      otherSport: "",
      trophyTour: "",
      phone: "",
      email: "",
    });
  };

  return (
    <div className="registration-container">
      <h1>Register Yourself for our official trophy tour!</h1>
      <h3>Fields marked with * are mandatory</h3>
      <form className="registration-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Your Name *</label>
          <input
            type="text"
            name="name"
            required
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div className="form-group">
          <label>Your Age *</label>
          <input
            type="number"
            name="age"
            onChange={(e) => setAge(e.target.value)}
            value={age}
            required
          />
        </div>
        <div className="form-group">
          <label>Your Gender *</label>
          <select
            name="gender"
            onChange={(e) => setGender(e.target.value)}
            value={gender}
            required
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Prefer not to say">Prefer not to say</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="form-group">
          <label>Name of School *</label>
          <input
            type="text"
            name="school"
            onChange={(e) => setSchool(e.target.value)}
            value={school}
            required
          />
        </div>
        <div className="form-group">
          <label>How long have you been playing football? *</label>
          <input
            type="text"
            name="footballExperience"
            onChange={(e) => setFootballExperience(e.target.value)}
            value={footballExperience}
            required
          />
        </div>
        <div className="form-group">
          <label>What other sport interests you? *</label>
          <input
            type="text"
            name="otherSport"
            onChange={(e) => setOtherSport(e.target.value)}
            value={otherSport}
            required
          />
        </div>
        <div className="form-group">
          <label>Email Address *</label>
          <input
            type="email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
            placeholder="Enter your email address"
          />
        </div>
        <div className="form-group">
          <label>Phone Number *</label>
          <input
            type="tel"
            name="phone"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            pattern="[0-9]{10}" // Ensures 10 digits
            required
            placeholder="Enter your 10-digit phone number"
          />
        </div>
        <div className="form-group">
          <label>
            Are you coming to the official trophy tour on 26th September 2024? *
          </label>
          <select
            name="trophyTour"
            onChange={(e) => setTrophyTour(e.target.value)}
            value={trophyTour}
            required
          >
            <option value="">Select an option</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div className="button-group">
          <button type="submit" className="submit-btn">
            Submit
          </button>
          <button type="button" className="reset-btn" onClick={handleReset}>
            Reset
          </button>
        </div>
      </form>
    </div>
  );
};

export default RegistrationForm;
