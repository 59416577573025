import "./YBDPComp.css"; // Import the CSS file
import image1 from "../../assets/images/kolkata/P1.jpeg";
import image2 from "../../assets/images/kolkata/P2.jpeg";
import image4 from "../../assets/images/kolkata/P4.jpeg";
import image5 from "../../assets/images/kolkata/P5.jpeg";
import image6 from "../../assets/images/kolkata/P6.jpeg";
import image7 from "../../assets/images/kolkata/P7.jpeg";
const YBDPComp = () => {
  const images = [image1, image2, image4, image5, image6, image7];

  return (
    <div className="YBDP-container">
      <h1>YOUNG PLAYER DEVELOPMENT PROGRAMME</h1>
      <p>
        Our Young Player Development Programme is designed to nurture the next
        generation of football stars. By combining top-tier coaching,
        personalized training, and holistic development, we aim to cultivate
        young talent from the grassroots level.
      </p>

      <div className="YBDP-gallery">
        {images.map((image, index) => (
          <img key={index} src={image} alt={`YBDP image ${index + 1}`} />
        ))}
      </div>
    </div>
  );
};

export default YBDPComp;
