import Navbar from "../../components/Navbar/Navbar";
import "./About.css"; // Import the CSS file for styling
import AboutComp from "../../components/AboutComp/AboutComp";

export default function About() {
  return (
    <>
      <Navbar />
      <div className="about">
        <AboutComp />
      </div>
    </>
  );
}
