import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "./OverViewCFG.css";
import coverImage from "../../assets/images/kolkata/P3.jpeg";

const OverViewCFG = () => {
  // Create a reference to the image element
  const imageRef = useRef(null);

  useEffect(() => {
    const bannerImage = imageRef.current;

    // Function to handle mouse movement over the image
    const handleMouseMove = (event) => {
      const { offsetX, offsetY, target } = event;
      const { clientWidth, clientHeight } = target;

      // Calculate rotation values based on cursor position
      const rotateX = (offsetY / clientHeight - 0.5) * 20; // Max 20 degrees rotation on X-axis
      const rotateY = (offsetX / clientWidth - 0.5) * 20; // Max 20 degrees rotation on Y-axis

      // Apply the rotation to the image
      bannerImage.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
    };

    // Function to reset the image when the cursor leaves
    const handleMouseLeave = () => {
      bannerImage.style.transform = `rotateX(0deg) rotateY(0deg)`; // Reset to default position
    };

    // Attach event listeners to the image
    bannerImage.addEventListener("mousemove", handleMouseMove);
    bannerImage.addEventListener("mouseleave", handleMouseLeave);

    // Clean up event listeners on component unmount
    return () => {
      bannerImage.removeEventListener("mousemove", handleMouseMove);
      bannerImage.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return (
    <section className="banner">
      <div className="banner-left">
        <h1>CITY FOOTBALL GROUP :</h1>
        <p>
          Manchester City Football Club is a professional football club based in
          Manchester, England. The club competes in the Premier League, the top
          tier of English football. The club has won 10 league titles, seven FA
          Cups, eight League Cups, seven FA Community Shields, one UEFA
          Champions League, one European Cup Winners' Cup, one UEFA Super Cup
          and one FIFA Club World Cup. Manchester City FC have recently been
          crowned 2023/24 Premier League champions, becoming the first men’s
          team in the history of English football to win four top-flight titles
          in a row.
        </p>
        <Link to="/cfg">
          <button className="neomorphic-btn">FIND OUT MORE</button>
        </Link>
      </div>
      <div className="banner-right">
        <img
          ref={imageRef} // Attach the ref to the image
          src={coverImage}
          alt="Young Player Development"
        />
      </div>
    </section>
  );
};

export default OverViewCFG;
