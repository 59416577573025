import "./TIGComp.css"; // Import the CSS file
const TIGComp = () => {
  return (
    <div className="TIG-container">
      <h1>TECHNO INDIA GROUP</h1>
      <p>
        For over three decades, Techno India Group has built a globally
        recognized brand. Founded in 1985 by the visionary Satyam Roychowdhury,
        the Group began as a small initiative and has since grown into a
        towering institution, deeply rooted in education. Pioneering computer
        education even before India's economic liberalization, they ignited a
        digital literacy revolution, foreseeing the critical role of technology
        in everyday life.{" "}
      </p>
      <p>
        Today, Techno India Group is a leader in engineering education,
        consistently ranking among the top institutions. Its reach extends far
        beyond education into diverse sectors, including entertainment,
        publication, health, hospitality, sports, and media. With 2
        universities, 22 engineering colleges, 13 B-schools, 19 public schools,
        40 IT training institutes, and a growing presence in India, Techno India
        Group provides unmatched opportunities for aspiring youth, fostering
        excellence and promoting holistic development across India and beyond.
      </p>
    </div>
  );
};

export default TIGComp;
