import Navbar from "../../components/Navbar/Navbar";
import "./Contact.css"; // Import the CSS file for styling
import ContactComp from "../../components/ContactComp/ContactComp";

export default function Contact() {
  return (
    <>
      <Navbar />
      <div className="contact">
        <ContactComp />
      </div>
    </>
  );
}
