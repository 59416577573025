import Navbar from "../../components/Navbar/Navbar";
import RegistrationForm from "../../components/RegistrationForm/RegistrationForm";
import "./registration.css"; // Import the CSS file for styling

export default function Registration() {
  return (
    <>
      <Navbar />
      <div className="registration-page">
        <RegistrationForm />
      </div>
    </>
  );
}
