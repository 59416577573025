import Navbar from "../../components/Navbar/Navbar";
import TIGComp from "../../components/TIGComp/TIGComp";
import "./TIGDetails.css"; // Import the CSS file for styling


export default function TIGDetails() {
  return (
    <>
      <Navbar />
      <div className="TIG-page">
        <TIGComp />
      </div>
    </>
  );
}
